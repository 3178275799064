import React, { useState, useEffect } from "react";
import axios from "axios";

const DrfApiFetch = () => {
  const [users, setUsers] = useState([]);
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    axios
      // .get("http://127.0.0.1:8000/hooksapi/users/", {
      //   headers: {
      //     Authorization: "Token 1cd5467cfab8b39c00e4794d037bac2bec4bdf09",
      //   },
      // })
      // .get("https://api.btime.online/hooksapi/users/", {
      //   headers: {
      //     Authorization: "Token 1cd5467cfab8b39c00e4794d037bac2bec4bdf09",
      //   },
      // })
      // .then((res) => {
      //   setUsers(res.data);
      // });
      // .get("http://127.0.0.1:8000/hooksapi/tasks/", {
      //   headers: {
      //     Authorization: "Token 1cd5467cfab8b39c00e4794d037bac2bec4bdf09",
      //   },
      // })
      .get("https://api.btime.online/hooksapi/tasks/", {
        headers: {
          Authorization: "Token c2546ed5ff16ca6976042c520889d2b682c45e90",
        },
      })
      .then((res) => {
        setTasks(res.data);
      });
  }, []);

  return (
    <div>
      <ul>
        {/* {tasks.map((task) => (
          <li key={task.id}>
            {task.title} {task.id}
          </li>
        ))} */}
        {users.map((user) => (
          <li key={user.id}>
            {user.username} {user.id}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DrfApiFetch;
